import React from "react";
import { ReactComponent as Arrow } from "../../assets/images/arrow-right.svg";
import { Link } from "react-router-dom";

const OrderCard = ({ order = {} }) => {
  return (
    <Link to={order.link} className="order-card">
      <div className="order-card__image-container">
        <img src={order.thumbnail} alt="Event" className="order-card__image" />
      </div>
      <div className="order-card__event">
        <h3 className="order-card__event-title">
          {order.event.name}{" "}
          {order?.status && (
            <span
              className={
                "order-card__order-status" +
                " order-card__order-status--" +
                order.statusType
              }
            >
              {"(" + order.statusText + ")"}
            </span>
          )}
        </h3>
        <span className="order-card__event-detail">{order.event.startsAt}</span>
        <span className="order-card__event-detail">{order.event.location}</span>
        <span className="order-card__event-detail">
          {order.ticketCount} Tickets
        </span>
      </div>
      <div className="order-card__order-summary">
        <h3 className="order-card__summary-price">{order.price} {order.currency}</h3>
        <p className="order-card__summary-date">{order.purchaseDate}</p>
      </div>
      <Arrow className="order-card__redirect-icon" />
    </Link>
  );
};

export default OrderCard;
