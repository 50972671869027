import axios from "axios";
import { useEffect, useState } from "react";

export function useGetUserCurrencyFromRegion() {
  const [currency, setCurrency] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://ipapi.co/json")
      .then((res) => {
        if (res?.status === 200) {
          setCurrency(res?.data?.country_name === "Egypt" ? "EGP" : "USD");
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  return { userCurrency: currency, isLoading };
}
