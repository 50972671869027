import React, { useState, useEffect } from "react";
import CTAPopup from "../components/Event/CTAPopup";
import { options as dateOptions } from "../utils/dateFormat";
import { options as timeOptions } from "../utils/timeFormat";
import { ReactComponent as PinOutlinedRed } from "../assets/images/pin-outlined.svg";
import { ReactComponent as ShareOutlinedRed } from "../assets/images/share-outlined-red.svg";
import * as api from "../api";
import ShareList from "../components/Utils/ShareList";
import ShareAndDirections from "../components/Utils/ShareAndDirections";
import { useHistory, useParams } from "react-router-dom";

const EventDetails = ({ eventId = null }) => {
  const history = useHistory();
  const params = useParams();
  const [event, setEvent] = useState({});
  const [id] = useState(params.id || eventId);
  const [showCta, setShowCta] = useState(false);
  const [disableCta, setDisableCta] = useState(false);
  const [ctaText, setCtaText] = useState("find passes");

  useEffect(() => {
    api.events
      .getEventById(id)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          let res = {
            title: response.data.name,
            description: response.data.description,
            image: response.data.banner,
            date:
              new Date(response.data.startsAt).toLocaleString("default", {
                month: "long",
              }) +
              " " +
              new Date(response.data.startsAt).toLocaleString("default", {
                day: "numeric",
              }) +
              ", " +
              new Date(response.data.startsAt).toLocaleString("default", {
                year: "numeric",
              }),
            artists: response.data.artists,
            location: response.data.location,
            locationLink: response.data.locationLink,
            startsAt: new Date(response.data.startsAt).toLocaleTimeString(
              [],
              timeOptions
            ),
          };
          let reservationStartTime = new Date(
            response.data.reservationStartsAt
          ).getTime();
          let reservationEndTime = new Date(
            response.data.reservationEndsAt
          ).getTime();
          let now = new Date().getTime();

          if (now < reservationStartTime) {
            setShowCta(true);
            setDisableCta(true);
            setCtaText("coming soon");
          } else if (now >= reservationStartTime && now <= reservationEndTime) {
            setShowCta(true);
            setDisableCta(false);
            setCtaText("find passes");
          } else {
            setShowCta(false);
          }
          setEvent(res);
        }
      })
      .catch(() => {
        history.replace("/");
      });
  }, []);

  return (
    <div
      className={"event-page" + (!showCta ? " event-page--full-height" : "")}
    >
      <div className="event-page__body event-page--event-details">
        <div className="event-page__info event-page__info--details-page">
          <div className="event-page__event-image-area">
            <img
              src={event.image}
              alt="event"
              className="event-page__event-image"
            />
            <div className="event-page__event-image-text">
              <p className="event-page__event-image-date">
                {event.date}
                {/* , {event.startsAt} */}
              </p>
              <p className="event-page__event-image-location">
                {event.location}
              </p>
              <h3 className="event-page__event-image-title">{event.title}</h3>
              <div className="event-page__event-image-btns event-page__event-image-btns--desktop">
                <ShareAndDirections
                  locationLink={event.locationLink}
                  url={`/booking/${id}`}
                  image={event.image}
                />
              </div>
            </div>
            {/* <div className="event-image-overlay"></div> */}
          </div>
        </div>
        {event?.description && (
          <div className="event-page__description event-page__description--details-page">
            {/* <h3 className="event-page__title">{event.title}</h3> */}
            <p className="event-page__paragraph">{event.description}</p>
            <div className="event-page__event-day-details">
              {/* <div className="event-page__event-day-detail-col">
              <p className="event-page__event-day-detail-title">Day 1</p>
              <p className="event-page__event-day-detail-text">Artist 1</p>
              <p className="event-page__event-day-detail-text">Artist 2</p>
              <p className="event-page__event-day-detail-text">Artist 3</p>
            </div>
            <div className="event-page__event-day-detail-col">
              <p className="event-page__event-day-detail-title">Day 2</p>
              <p className="event-page__event-day-detail-text">Artist 4</p>
              <p className="event-page__event-day-detail-text">Artist 5</p>
              <p className="event-page__event-day-detail-text">Artist 6</p>
            </div>
            <div className="event-page__event-day-detail-col">
              <p className="event-page__event-day-detail-title">Day 3</p>
              <p className="event-page__event-day-detail-text">Artist 7</p>
              <p className="event-page__event-day-detail-text">Artist 8</p>
              <p className="event-page__event-day-detail-text">Artist 9</p>
            </div> */}
            </div>
          </div>
        )}
        <div className="event-page__event-image-btns event-page__event-image-btns--mobile">
          <ShareAndDirections
            locationLink={event.locationLink}
            url={`/booking/${id}`}
            image={event.image}
          />
        </div>
      </div>
      {showCta && (
        <CTAPopup
          actionHandler={() => history.push(`/booking/${id}`)}
          variation={"single"}
          disabled={disableCta}
          text={ctaText}
        />
      )}
    </div>
  );
};

export default EventDetails;
