import React, { useState, useEffect } from "react";
import EventCard from "./eventCard";

const Events = ({ events = [], showEvents = false }) => {
  const [currentEvents, setCurrentEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  useEffect(() => {
    let past = [];
    let upcoming = [];
    let current = [];
    for (let i = 0; i < events.length; i++) {
      let reservationStartTime = new Date(
        events[i].reservationStartsAt
      ).getTime();
      let reservationEndTime = new Date(events[i].reservationEndsAt).getTime();
      let now = new Date().getTime();

      if (now < reservationStartTime) {
        upcoming.push(events[i]);
      } else if (now >= reservationStartTime && now <= reservationEndTime) {
        current.push(events[i]);
      } else {
        past.push(events[i]);
      }
    }
    
    current.sort((a, b) => new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime());
    past.sort((a, b) => new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime());
    upcoming.sort((a, b) => new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime());
    
    setCurrentEvents(current);
    setUpcomingEvents(upcoming);
    setPastEvents(past);
  }, [events]);

  return (
    <React.Fragment>
      {showEvents && (
        <div id="events">
          {currentEvents.length > 0 && (
            <div className="events">
              <div className="events__header">
                <h3 className="events__title">Popular Now</h3>
              </div>
              {currentEvents.map((event, index) => (
                <React.Fragment key={index}>
                  <EventCard
                    event={event}
                    showPassesButton={true}
                    passesButtonText={"find passes"}
                    showMoreInfo={true}
                  />
                </React.Fragment>
              ))}
            </div>
          )}
          {upcomingEvents.length > 0 && (
            <div className="events">
              <div className="events__header">
                <h3 className="events__title">Upcoming</h3>
              </div>
              {upcomingEvents.map((event, index) => (
                <React.Fragment key={index}>
                  <EventCard
                    event={event}
                    showPassesButton={true}
                    passesButtonText={"coming soon"}
                    showMoreInfo={true}
                    disablePassesButton={true}
                  />
                </React.Fragment>
              ))}
            </div>
          )}
          {pastEvents.length > 0 && (
            <div className="events">
              <div className="events__header">
                <h3 className="events__title">Past</h3>
              </div>
              {pastEvents.map((event, index) => (
                <React.Fragment key={index}>
                  <EventCard
                    event={event}
                    showPassesButton={false}
                    showMoreInfo={false}
                  />
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Events;
