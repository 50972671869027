import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const checkPayment = async (id) => {
  const response = await axios.post(
    routes.checkPayment,
    {
      order: id,
    },
    AXIOS_DEFAULT_CONFIG
  );
  return response;
};
export default checkPayment;
