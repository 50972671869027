import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const payReservation = async (
  reservation,
  order,
  type,
  sendEmail,
  amount,
  solution
) => {
  const body = { type, sendEmail };
  if (amount) {
    body["amount"] = +amount * 100; //convert to cents
  }
  if (solution) {
    body["payment_solution"] = solution;
  }
  const response = await axios.post(
    routes.payReservation(reservation, order),
    {
      ...body,
    },
    AXIOS_DEFAULT_CONFIG
  );
  return response;
};
export default payReservation;
