import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { formatNumbers } from "./../utils/formatNumbers";
import CheckoutReceipt from "./../components/Receipt/CheckoutReceipt";
import { options as timeOptions } from "../utils/timeFormat";
import { options as dateOptions } from "../utils/dateFormat";
import { ReactComponent as SuccessIcon } from "../assets/images/order-success-checkmark.svg";
import * as api from "../api";
import PaymentConfirmationEventBanner from "../components/Event/PaymentConfirmationEventBanner";
import PaymentService from "../components/Payment/PaymentService";

const UpgradeTicket = () => {
  const [event, setEvent] = useState({});
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [upgrades, setUpgrades] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [status, setStatus] = useState("");
  const [oldTier, setOldTier] = useState({});
  const [newTier, setNewTier] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState({});
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [commission, setCommission] = useState(0);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getCartTotal();
  }, [upgrades]);

  useEffect(() => {
    if (params.token) {
      if (location?.search?.includes("payment_intent")) {
        history.replace(`/upgrade-ticket/${params.token}`);
      }
      api.orders
        .getUpgradeOrder(params.token)
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            let eventObj = {
              location: response.data.event.location,
              locationLink: response.data.event.locationLink,
              title: response.data.event.name,
              image: response.data.event.banner,
              time: new Date(response.data.event.startsAt).toLocaleTimeString(
                [],
                timeOptions
              ),
              date: new Date(response.data.event.startsAt).toLocaleDateString(
                [],
                dateOptions
              ),
              id: response.data.event.id,
            };
            let upgradeObj = {
              quantity: 1,
              price: response.data.amount / 100,
              name: "Ticket upgrade",
            };
            let upgradeArray = [];
            upgradeArray.push(upgradeObj);
            setUpgrades(upgradeArray);
            setEvent(eventObj);
            setStatus(response.data.upgradeStatus);
            setOldTier(response.data.initialTier);
            setNewTier(response.data.newTier);
            setPaymentData({
              url: response?.data?.cardPaymentUrl,
              solution: response?.data?.paymentSolution,
              orderId: response?.data?.pk,
              clientSecret: response?.data?.extraData?.clientSecret,
            });
            setLoading(false);
            setCommission(response?.data?.newTier?.region?.commissionRate);
          } else {
            history.push("/");
          }
        })
        .catch(() => {
          history.push("/");
        });
    } else {
      history.push("/");
    }
  }, []);

  const getCartTotal = () => {
    let sum = 0;
    let price = 0;
    for (let i = 0; i < upgrades.length; i++) {
      sum += upgrades[i].quantity;
      price += upgrades[i].quantity * upgrades[i].price;
    }
    setSubtotal(price);
  };

  return (
    <React.Fragment>
      {!loading && (
        <div className="payment-confirmation">
          <PaymentConfirmationEventBanner event={event} />

          <div className="payment-confirmation__details">
            <div className="payment-confirmation__title">
              <div className="payment-confirmation__title-container">
                {status === "done" && (
                  <SuccessIcon className="payment-confirmation__success-icon" />
                )}
                <h3
                  className={
                    "payment-confirmation__status" +
                    (status === "done"
                      ? " payment-confirmation__status-success"
                      : "")
                  }
                >
                  {status === "done" && "Payment Successful"}
                  {status === "pending" && "Pending Payment"}
                </h3>
              </div>
            </div>
            <div className="payment-confirmation__upgrade-dialog">
              <p className="payment-confirmation__upgrade-dialog-title">
                TICKET UPGRADE
              </p>
              <p className="payment-confirmation__upgrade-dialog-description">
                {oldTier?.name +
                  " " +
                  oldTier?.nameLine2 +
                  " " +
                  oldTier?.nameLine3 +
                  " (" +
                  oldTier?.price / 100 +
                  ` ${oldTier?.region?.currency}) `}
                <span className="payment-confirmation__upgrade-dialog-description payment-confirmation__upgrade-dialog-description--bold">
                  to{" "}
                </span>
                {newTier?.name +
                  " " +
                  newTier?.nameLine2 +
                  " " +
                  newTier?.nameLine3 +
                  " (" +
                  newTier?.price / 100 +
                  ` ${newTier?.region?.currency}) `}
              </p>
            </div>
            <CheckoutReceipt
              tickets={upgrades}
              subtotal={subtotal}
              setGrandTotal={setGrandTotal}
              grandTotal={grandTotal}
              currency={newTier?.region?.currency}
              commission={commission}
            />
            <div className="checkout__grand-total">
              <h3 className="checkout__grand-total-title">Total</h3>
              <div className="checkout__grand-total-value">
                <p>
                  {formatNumbers(grandTotal)} {newTier?.region?.currency}
                </p>
              </div>
            </div>
            {status === "done" ? (
              <></>
            ) : (
              <div className="payment-confirmation__btn-container">
                <button
                  type="button"
                  className="btn btn--primary payment-confirmation__btn"
                  onClick={() => {
                    setShowPaymentPopup(true);
                    setPaymentLoading(true);
                  }}
                >
                  Pay
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {showPaymentPopup && (
        <PaymentService
          type={paymentData?.solution}
          onClose={() => {
            setShowPaymentPopup(false);
          }}
          setLoading={setPaymentLoading}
          stripeClientSecret={paymentData?.clientSecret}
          isStripeSetup={false}
          stripeRedirectUrl={`/upgrade-ticket/${paymentData?.orderId}`}
          cardPaymentUrl={paymentData?.url}
          loading={paymentLoading}
        />
      )}
    </React.Fragment>
  );
};

export default UpgradeTicket;
