import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ShareIcon } from "../../assets/images/share-grey-network.svg";
import ShareList from "./../Utils/ShareList";

const EventCard = ({
  event = {},
  showPassesButton = true,
  passesButtonText = "",
  showMoreInfo = true,
  disablePassesButton = false,
}) => {
  const [showShareList, setShowShareList] = useState(false);
  return (
    /*<Link to={`/booking/${event.pk}`} className="event-card">*/
    <div className="event-card">
      {showPassesButton ? (
        <Link
          to={`/booking/${event.pk}`}
          className="event-card__image-container"
        >
          <img src={event.banner} alt="" className="event-card__image" />
        </Link>
      ) : (
        <div className="event-card__image-container">
          <img src={event.banner} alt="" className="event-card__image" />
        </div>
      )}
      <div className="event-card__text">
        {/* <p className="event-card__artist">{event.artists[0].name}</p> */}

        {showPassesButton ? (
          <Link to={`/booking/${event.pk}`} className="event-card__event-name">
            {event.name}
          </Link>
        ) : (
          <p className="event-card__event-name event-card__event-name--disabled">
            {event.name}
          </p>
        )}

        <p className="event-card__description">{event.description}</p>
        <span className="event-card__info">
          <span className="event-card__date">
            {new Date(event.startsAt).toLocaleDateString("uk")}
          </span>
          <span className="event-card__location">{event.location}</span>
          {/* <span className="event-card__price">From 3000 EGP</span> */}
        </span>
        <Link
          to={`/events/${event.pk}`}
          className="event-card__see-more event-card__see-more--mobile"
        >
          more info
        </Link>
      </div>
      <div className="event-card__cta">
        {showMoreInfo && (
          <Link to={`/events/${event.pk}`} className="event-card__see-more">
            more info
          </Link>
        )}
        {showPassesButton && (
          <React.Fragment>
            {disablePassesButton ? (
              <p className="btn btn--disabled event-card__btn">
                {passesButtonText}
              </p>
            ) : (
              <Link
                to={`/booking/${event.pk}`}
                className="btn btn--primary event-card__btn"
              >
                {passesButtonText}
              </Link>
            )}
          </React.Fragment>
        )}
      </div>
      <div className="event-card__share-area">
        <ShareIcon
          className="event-card__share-icon"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowShareList(!showShareList);
          }}
        />
        {showShareList && (
          <ShareList
            close={() => setShowShareList(false)}
            url={`/booking/${event.pk}`}
            title={event?.name}
          />
        )}
      </div>
    </div>
  );
};

export default EventCard;
