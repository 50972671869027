import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ScrollDownIcon } from "../../assets/images/scroll-down-icon.svg";
const FeaturedEvent = ({
  image = null,
  title = null,
  description = null,
  time = null,
  artist = null,
  id = null,
  showBrowseButton = false,
  location = "",
  reservationStartsAt = "",
  reservationEndsAt = "",
}) => {
  const [processedDescription, setProcessedDescription] = useState("");
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [actionBtnText, setActionBtnText] = useState("find passes");
  const [showActionBtn, setShowActionBtn] = useState(true);
  const [disablseActionBtn, setDisableActionBtn] = useState(false);

  useEffect(() => {
    if (description?.length > 400) {
      setProcessedDescription(description.substring(0, 400));
      setShowMoreInfo(true);
    } else {
      setProcessedDescription(description);
      setShowMoreInfo(false);
    }
  }, [description]);

  useEffect(() => {
    if (reservationEndsAt && reservationStartsAt) {
      let reservationStartTime = new Date(reservationStartsAt).getTime();
      let reservationEndTime = new Date(reservationEndsAt).getTime();
      let now = new Date().getTime();

      if (now < reservationStartTime) {
        setActionBtnText("coming soon");
        setShowActionBtn(true);
        setDisableActionBtn(true);
      } else if (now >= reservationStartTime && now <= reservationEndTime) {
        setActionBtnText("find passes");
        setShowActionBtn(true);
        setDisableActionBtn(false);
      } else {
        setShowActionBtn(false);
      }
    }
  }, [reservationStartsAt, reservationEndsAt]);

  return (
    <Link to={`/booking/${id}`} className="featured">
      <img src={image} alt="featured event image" className="featured__image" />
      {/* <div className="featured__body">
        <div to={`/events/${id}`} className="featured__heading">
          <p className="featured__time">{time}</p>
          <p className="featured__title">{title}</p>
        </div>
         {artist && (
          <React.Fragment>
            <p className="featured__artist-title">Artist</p>
            <p className="featured__artist-name">{artist}</p>
          </React.Fragment>
        )} 
        <p className="featured__artist-title">Location</p>
        <p className="featured__location">{location}</p>
        <p className="featured__description">
          {processedDescription}
          {showMoreInfo && (
            <Link to={`/events/${id}`} className="featured__more-info">
              Read more
            </Link>
          )}
        </p>
      </div> */}
      {/* {showActionBtn && (
        <React.Fragment>
          {disablseActionBtn ? (
            <p className="featured__link">
              <button className="featured__action featured__action--disabled">
                {actionBtnText}
              </button>
            </p>
          ) : (
            <Link className="featured__link" to={`/booking/${id}`}>
              <button className="featured__action">{actionBtnText}</button>
            </Link>
          )}
        </React.Fragment>
      )} */}
      {showBrowseButton && (
        <a href="#events" className="featured__link-to-events" onClick={(e)=> e.stopPropagation()}>
          <ScrollDownIcon className="featured__scroll-icon" />
          Browse Events
        </a>
      )}
      <Link
        to={`/events/${id}`}
        className="event-card__see-more event-card__see-more--mobile featured__see-more"
      >
        more info
      </Link>
    </Link>
  );
};

export default FeaturedEvent;
