import React from "react";
import CardIcon from "../../assets/images/visa-icon.svg";
import CardIcon2 from "../../assets/images/mastercard-icon.svg";
import ValUIcon from "../../assets/images/valu.webp";
import FawryIcon from "../../assets/images/fawry.png";
import SymplLogo from "./../../assets/images/sympl-logo.png";

const PaymentMethods = ({
  handlePaymentMethodSelect = () => {},
  selectedPayment = "",
  showSympl = false,
  showValu = false,
  showFawry = false,
  showLocalPayOptions = false,
  showCard = true,
  selectableMethods = true,
}) => {
  return (
    <div className="payment-methods-list">
      {showCard && (
        <div
          className={
            "payment-method" +
            (selectedPayment === "card" ? " payment-method--selected" : "")
          }
        >
          <label htmlFor="card" className="payment-method__body">
            {selectableMethods && (
              <label className="custom-radio">
                <input
                  type="radio"
                  checked={selectedPayment === "card"}
                  onChange={(e) => handlePaymentMethodSelect(e.target.value)}
                  value="card"
                  name="paymentmethod"
                  id="card"
                />
                <span className="checkmark"></span>
              </label>
            )}
            <p className="payment-method__name">
              Credit card 
              <br />
              <span className="payment-method__description">
                International charges may apply. Please note that some credit
                card payments may be declined due to regulatory reasons. If you
                experience difficulties please try again with a different card.
              </span>
            </p>

            <div className="payment-method__icons">
              <img
                src={CardIcon}
                alt="visa"
                className="payment-method__icon payment-method__icon--image"
              />
              <img
                src={CardIcon2}
                alt="MasterCard"
                className="payment-method__icon payment-method__icon--image"
              />
            </div>
          </label>
        </div>
      )}
      {showLocalPayOptions && (
        <div
          className={
            "payment-method" +
            (selectedPayment === "subsbase" ? " payment-method--selected" : "")
          }
        >
          <label htmlFor="subsbase" className="payment-method__body">
            {selectableMethods && (
              <label className="custom-radio">
                <input
                  type="radio"
                  checked={selectedPayment === "subsbase"}
                  onChange={(e) => handlePaymentMethodSelect(e.target.value)}
                  value="subsbase"
                  name="paymentmethod"
                  id="subsbase"
                />
                <span className="checkmark"></span>
              </label>
            )}
            <p className="payment-method__name">
              <span className="payment-method__description">
                Once your booking is approved, 
                you will receive a payment link giving you the option to pay in 
                full using your Credit Card or in installments using ValU.
              </span>
            </p>
          </label>
        </div>
      )}
      {showFawry && (
        <div
          className={
            "payment-method" +
            (selectedPayment === "fawry" ? " payment-method--selected" : "")
          }
        >
          <label htmlFor="fawry" className="payment-method__body">
            {selectableMethods && (
              <label className="custom-radio">
                <input
                  type="radio"
                  checked={selectedPayment === "fawry"}
                  onChange={(e) => handlePaymentMethodSelect(e.target.value)}
                  value="fawry"
                  name="paymentmethod"
                  id="fawry"
                />
                <span className="checkmark"></span>
              </label>
            )}
            <p className="payment-method__name">
              Full Payment
              <br />
              <span className="payment-method__description">
                Pay your order in Full using Fawry (you may use myFawryApp or go
                to a Fawry Kiosk). Please be sure to make note your Fawry
                payment reference number before closing the payment window,
                otherwise you will need to generate a new one.
              </span>
            </p>
            <div className="payment-method__icons">
              <img
                src={FawryIcon}
                alt="Fawry"
                className="payment-method__icon payment-method__icon--image--large"
              />
            </div>
          </label>
        </div>
      )}
      {showValu && (
        <div
          className={
            "payment-method" +
            (selectedPayment === "valU" ? " payment-method--selected" : "")
          }
        >
          <label htmlFor="valu" className="payment-method__body">
            {selectableMethods && (
              <label className="custom-radio">
                <input
                  type="radio"
                  checked={selectedPayment === "valu"}
                  onChange={(e) => handlePaymentMethodSelect(e.target.value)}
                  value="valu"
                  name="paymentmethod"
                  id="valu"
                />
                <span className="checkmark"></span>
              </label>
            )}
            <p className="payment-method__name">
              installments
              <br />
              <span className="payment-method__description">
                Pay your order by installments using our partner ValU. Upon
                choosing this option you will be shown the various ValU options.
                Please make sure you're a ValU member or this option will fail
              </span>
            </p>

            <div className="payment-method__icons">
              <img
                src={ValUIcon}
                alt="valu"
                className="payment-method__icon payment-method__icon--image--large"
              />
            </div>
          </label>
        </div>
      )}
      {showSympl && (
        <div
          className={
            "payment-method" +
            (selectedPayment === "installment"
              ? " payment-method--selected"
              : "")
          }
        >
          <label htmlFor="installment" className="payment-method__body">
            {selectableMethods && (
              <label className="custom-radio">
                <input
                  type="radio"
                  checked={selectedPayment === "installment"}
                  onChange={(e) => handlePaymentMethodSelect(e.target.value)}
                  value="installment"
                  name="paymentmethod"
                  id="installment"
                />
                <span className="checkmark"></span>
              </label>
            )}
            <p className="payment-method__name">Sympl</p>
            <div className="payment-method__icons">
              <img
                src={SymplLogo}
                alt="sympl"
                className="payment-method__icon payment-method__icon--image"
              />
            </div>
          </label>
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;
