import React, { useEffect } from "react";
import Loader from "../Utils/Loader";
import qs from "qs";
import * as api from "./../../api";

const PaymentConfirmationRedirect = ({ location, history }) => {
  useEffect(() => {
    let orderId = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_order_id;
    let solution = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).solution;
    if (!orderId) {
      history.replace("/");
    } else {
      if (solution == "stripe") {
        api.reservations
          .checkPayment(orderId)
          .then(() => {
            history.replace(`/orders/${orderId}`);
          })
          .catch(() => {
            history.replace(`/orders/${orderId}`);
          });
      } else {
        history.replace(`/orders/${orderId}`);
      }
    }
  }, []);

  return (
    <div className="payment-redirect">
      <div className="payment-redirect__loader">
        <Loader />
      </div>
    </div>
  );
};

export default PaymentConfirmationRedirect;
