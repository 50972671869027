import React from "react";

const EmptyPageWithMessage = ({ message = "" }) => {
  return (
    <div className="empty-page">
      <p className="empty-page__message">{message}</p>
    </div>
  );
};

export default EmptyPageWithMessage;
