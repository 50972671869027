import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Stripe from "./Stripe";
import { stripe_publishable_id } from "../../../constants";
import { ReactComponent as CloseIcon } from "./../../../assets/images/cross.svg";
import Overlay from "../../Utils/Overlay";
import { useEffect } from "react";

const StripePaymentProvider = ({
  clientSecret,
  close,
  setLoading,
  isSetup,
  redirectUrl,
  onPaymentFail = () => {}
}) => {
  const stripePromise = loadStripe(stripe_publishable_id);

  useEffect(() => {
    setLoading(false);
  }, []);

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <React.Fragment>
      <div className="popup payment-popup" style={{ padding: "4rem" }}>
        <CloseIcon className="popup__close" onClick={close} />
        <div className="popup__body">
          <Elements stripe={stripePromise} options={options}>
            <Stripe isSetup={isSetup} redirectUrl={redirectUrl} onPaymentFail={onPaymentFail}/>
          </Elements>
        </div>
      </div>
      <Overlay darkTheme={true} handleOnClick={close} />
    </React.Fragment>
  );
};

export default StripePaymentProvider;
