export const formatNumbers = (number, enforceFormatting = false) => {
    if (Number.isInteger(number) && !enforceFormatting) {
        return number;
    }
    else {
        return Number(number).toFixed(2);
    }
}

export const formatNumbers0 = (number) => {
    if (Number.isInteger(number)) {
        return number;
    }
    else {
        return Number(number).toFixed(0);
    }
}