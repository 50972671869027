import React from "react";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { currency, seatsio_public_key, ticketLimit } from "../../constants";

const SeatsSelector = ({
  eventKey = "",
  onSelect = () => {},
  onDeselect = () => {},
  priceMapper = [],
  onChartLoaded = () => {},
}) => {
  return (
    <SeatsioSeatingChart
      workspaceKey={seatsio_public_key}
      event={eventKey}
      region="eu"
      onObjectSelected={onSelect}
      onObjectDeselected={onDeselect}
      pricing={priceMapper}
      priceFormatter={(price) => price + ` ${currency}`}
      onChartRendered={onChartLoaded}
      maxSelectedObjects={ticketLimit}
    />
  );
};

export default SeatsSelector;
