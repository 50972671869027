import React, { useState, useEffect } from "react";
import { ReactComponent as DropdownIcon } from "../assets/images/dropdown.svg";
import * as api from "../api";
import { hardcoded_event } from "../constants";
import { faqsList } from "../utils/static_faqs";

const Help = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    if (hardcoded_event) {
      api.faqs.list().then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setFaqs(response.data.results);
        }
      });
    }
    else {
      setFaqs(faqsList)
    }
  }, []);

  return (
    <div className="help-page">
      <div className="help-page__title-container">
        <div className="help-page__title">
          HELP {"&"} <br />
          FAQs
        </div>
      </div>
      <div className="help-page__content">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="faq"
            onClick={() => {
              let all = [...faqs];
              let current = { ...all[index] };
              current.expanded =
                current.expanded === undefined ? true : !current.expanded;
              all[index] = current;
              setFaqs(all);
            }}
          >
            <div className="faq__title">
              <p className="faq__question">{faq.question}</p>
              <DropdownIcon
                className={
                  "faq__icon" +
                  (faqs[index].expanded ? " faq__icon--expanded" : "")
                }
              />
            </div>
            <p
              className={
                "faq__answer" +
                (faqs[index].expanded ? " faq__answer--expanded" : "")
              }
            >
              {faq.answer}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Help;
