import React, { useState, useEffect } from "react";
import Overlay from "./../Utils/Overlay";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";
import axios from "axios";

const PaymentIframe = ({
  url,
  close = () => {},
  setLoading = () => {},
  loading = null,
  shouldCheckUrl = false
}) => {
  const [error, setError] = useState("");

  const onLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (url && shouldCheckUrl) {
      axios
        .get(url)
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            setError("");
          }
        })
        .catch(() => {
          setError("Payment link has expired");
          setLoading(false);
        });
    }
  }, [url, shouldCheckUrl]);

  return (
    <React.Fragment>
      {url && (
        <div
          className={
            "popup payment-popup" + (loading ? " payment-iframe--loading" : "")
          }
        >
          <CloseIcon className="popup__close" onClick={close} />
          <div className="popup__body">
            {error == "" ? (
              <iframe
                target="self"
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
                className="payment-iframe"
                id="frame"
                src={url.length == 0 ? "" : url}
                frameBorder="0"
                onLoad={onLoad}
              ></iframe>
            ) : (
              <p className="error-message popup__error-message">{error}</p>
            )}
          </div>
        </div>
      )}
      {url && !loading && <Overlay darkTheme={true} handleOnClick={close} />}
    </React.Fragment>
  );
};

export default PaymentIframe;
