import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";
import Overlay from "./Overlay";
import PaymentMethods from "./PaymentMethods";
import { formatNumbers } from "../../utils/formatNumbers";

const PaymentSelectorPopup = ({
  close = () => {},
  handleSubmit = () => {},
  showPaymentOptions = false,
  customAmount = "",
  setCustomAmount = () => {},
  remainingBalance = 0,
  minAmount = 0,
  currency = "",
  showSplitPaymentOption = false,
}) => {
  const [selectedPayment, setSelectedPayment] = useState("card");
  const [paymentType, setPaymentType] = useState("full");

  const validateAmount = () => {
    if (!customAmount) {
      return "Amount is required";
    }
    if (customAmount?.toString()?.split(".")?.[1]?.length === 0) {
      return "Amount is invalid";
    }
    if (customAmount < minAmount) {
      return `Minimum payment is ${formatNumbers(minAmount, true)} ${currency}`;
    }
    if (customAmount > remainingBalance) {
      return `Maximum payment is ${formatNumbers(
        remainingBalance,
        true
      )} ${currency}`;
    }
    if (
      +remainingBalance - +customAmount !== 0 &&
      +remainingBalance - +customAmount < +minAmount
    ) {
      return `Remaining balance must be at least ${formatNumbers(
        minAmount,
        true
      )} ${currency}`;
    }
    return null;
  };

  return (
    <React.Fragment>
      <div className="popup payment-selector">
        {<CloseIcon className="popup__close" onClick={close} />}
        <div className="popup__body payment-selector__body">
          <div className="payment-selector__header">
            <h3 className="payment-selector__title">Payment Method</h3>
          </div>
          <PaymentMethods
            selectedPayment={selectedPayment}
            handlePaymentMethodSelect={(value) => setSelectedPayment(value)}
            showSympl={false}
            showValu={false}
            showFawry={false}
            showCard={true}
          />
          {selectedPayment && showPaymentOptions && (
            <div className="payment-methods-list__amount-section">
              <div>
                <label htmlFor="full" className="payment-method__body">
                  <label className="custom-radio">
                    <input
                      type="radio"
                      checked={paymentType === "full"}
                      onChange={(e) => {
                        setCustomAmount(undefined);
                        setPaymentType(e.target.value);
                      }}
                      value="full"
                      name="paymentType"
                      id="full"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p className="payment-method__name">
                    Pay full amount (
                    {`${formatNumbers(remainingBalance, true)} ${currency}`})
                    <br />
                    <span className="payment-methods-list__custom-input-description"></span>
                  </p>
                </label>
                {showSplitPaymentOption && (
                  <label htmlFor="partial" className="payment-method__body">
                    <label className="custom-radio">
                      <input
                        type="radio"
                        checked={paymentType === "partial"}
                        onChange={(e) => setPaymentType(e.target.value)}
                        value="partial"
                        name="paymentType"
                        id="partial"
                      />
                      <span className="checkmark"></span>
                    </label>
                    <p className="payment-method__name">
                      Pay partial amount <br />
                      <span className="payment-methods-list__custom-input-description">
                        You can pay the amount in split payments across multiple
                        cards using the same payment link. You have a maximum of
                        48 hours to pay your balance or the reservation will be
                        cancelled and your already paid money returned to you.
                      </span>
                    </p>
                  </label>
                )}
              </div>
              {paymentType === "partial" && (
                <div>
                  <input
                    value={customAmount}
                    onChange={(e) => {
                      if (
                        e.target.value &&
                        !/^(0|[1-9]\d*)(\.\d*)?$/.test(e.target.value)
                      ) {
                        return;
                      }
                      setCustomAmount(e.target.value);
                    }}
                    type="text"
                    className="payment-methods-list__custom-input"
                    placeholder="Amount"
                  />
                  {validateAmount() !== null && (
                    <p className="error-message payment-methods-list__custom-input-error">
                      {validateAmount()}
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
          <button
            className={"btn btn--primary payment-selector__btn"}
            onClick={() =>
              handleSubmit(selectedPayment, paymentType === "full")
            }
            disabled={
              !selectedPayment ||
              (showPaymentOptions &&
                paymentType === "partial" &&
                validateAmount() !== null)
            }
          >
            Pay
          </button>
          <button
            className="btn btn--secondary payment-selector__btn"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </div>
      <Overlay darkTheme={true} handleOnClick={close} />
    </React.Fragment>
  );
};

export default PaymentSelectorPopup;
