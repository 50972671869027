export const faqsList = [
  {
    question: "I’m having trouble paying with my card, what should I do?",
    answer:
      "The most common issue is with the card issuer. We recommend trying a different card, device, or browser. If the problem persists, contact your card provider.",
  },
  {
    question: 'What does "pending booking" mean?',
    answer:
      "It means your booking is under review by our sales team. You’ll receive an update within 48 hours.",
  },
  {
    question: "Can I refund or transfer my ticket once it has been issued?",
    answer:
      "Tickets are strictly non-exchangeable and non-refundable as per our policy.",
  },
  {
    question:
      'I received an email saying my reservation was rejected due to "ratio." What does this mean?',
    answer:
      "This means your reservation was submitted with an unequal gender ratio. Please re-submit with a 1:1 ratio for approval.",
  },
  {
    question: "Are on-door tickets available at your events?",
    answer:
      "Keep an eye on our social media for announcements about on-door ticket availability for each event.",
  },
  {
    question: "What ID do I need to bring for entry?",
    answer: "You must bring a valid ID that matches the name on your ticket.",
  },
  {
    question: "Can I re-enter the event once I’ve left?",
    answer:
      "Re-entry policies vary by event and venue. Please refer to the specific event details for more information.",
  },
  {
    question: "What items are prohibited at your events?",
    answer:
      "Prohibited items include weapons, illegal substances, and outside food or drink. Check the event page for a full list of restricted items.",
  },
  {
    question: "What is your policy on photography and videography?",
    answer:
      "Professional photography and videography without prior approval are not permitted. However, you are welcome to take personal photos and videos.",
  },
  {
    question: 'I have an issue. How can I reach you?',
    answer:
      'For any concerns, feel free to email us at: info@nacellegrooves.com.',
  }
];
