import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

const Stripe = ({ isSetup, redirectUrl, onPaymentFail = () => {} }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }
    const return_url = redirectUrl
      ? window.location.origin + redirectUrl
      : window.location.href;

    if (isSetup) {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url,
        },
      });

      if (error) {
        setErrorMessage(error.message);
      }
    } else {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url,
        },
      });

      if (error) {
        onPaymentFail();
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <button
          disabled={!stripe}
          className="btn btn--primary"
          style={{
            backgroundColor: "black",
            color: "white",
          }}
        >
          Submit
        </button>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </form>
  );
};

export default Stripe;
