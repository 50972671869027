import React, { useEffect, useState } from "react";
import FeaturedEvent from "../components/Event/FeaturedEvent";
import * as api from "../api";
import Events from "../components/EventsBrowse/events";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Loader from "../components/Utils/Loader";
import { useIsMobileScreenSize } from "../utils/hooks";

const LandingPage = () => {
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.events
      .list()
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          let all = response.data.results;
          const featured = [];
          for (let i = 0; i < all.length; i++) {
            if (
              all[i].isFeatured &&
              new Date(all[i]?.reservationEndsAt) > new Date()
            ) {
              featured.push(all[i]);
            }
          }
          setFeaturedEvents(featured);
          setEvents(response.data.results);
        }
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  const isMobile = useIsMobileScreenSize();

  if (isLoading) {
    return (
      <div className="page-loader-container">
        <Loader />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        interval={5000}
        showArrows={isMobile ? false : true}
      >
        {featuredEvents.map((featuredEvent) => (
          <FeaturedEvent
            image={featuredEvent?.banner}
            title={featuredEvent?.name}
            description={featuredEvent?.description}
            time={
              new Date(featuredEvent.startsAt).toLocaleString("default", {
                month: "long",
              }) +
              " " +
              new Date(featuredEvent.startsAt).toLocaleString("default", {
                day: "numeric",
              }) +
              ", " +
              new Date(featuredEvent.startsAt).toLocaleString("default", {
                year: "numeric",
              })
            }
            artist={featuredEvent?.artists[0]?.name}
            id={featuredEvent?.pk}
            showBrowseButton={events?.length > 1}
            location={featuredEvent?.location}
            reservationStartsAt={featuredEvent?.reservationStartsAt}
            reservationEndsAt={featuredEvent?.reservationEndsAt}
          />
        ))}
      </Carousel>
      <Events events={events} showEvents={events?.length > 1} />
    </React.Fragment>
  );
};

export default LandingPage;
